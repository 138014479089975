import Vue from 'vue'
// import App from './App.vue'
// import App from '@/views/huixiang/index.vue'
import App from '@/views/ecore/index.vue'
import Vant from 'vant';




// import md5 from 'js-md5';

// import 'vant/lib/index.css';
// import VueCookies from 'vue-cookies'

// Vue.config.productionTip = false
Vue.use(Vant)



// Vue.prototype.$md5 = md5;
// // Vue.prototype.$sha1 = sha1;
// Vue.prototype.$cookies = VueCookies;

new Vue({
  render: h => h(App),


}).$mount('#app')
