<template>
  <div id="App">
    <div class="box">
      <img
        src="https://ecore-xcx-img.oss-cn-beijing.aliyuncs.com/client/ecore/eb58d4c17ca76688bfc5d34b7fa69e8.png"
        alt=""
      />
      <div class="form_box">
        <div class="form_list">
          <span>姓名</span>
          <input type="text" v-model="form.name" />
        </div>
        <div class="form_list">
          <span>手机号</span>
          <input type="text" v-model="form.mobile" />
        </div>
        <div class="form_list select">
          <div>
            <select v-if="isShow" name="province" id="province" v-model="form.province" @click="selected==1" style="width:3.65rem;">
              <option
                v-for="(item, index) in option1"
                :key="index"
                :value="index"
                @click="selected==0"
              >
                {{ item.text }}
              </option>
            </select>

            <select v-else name="province" id="province" v-model="province" style="width:3.65rem;">
              <option>
                {{ province }}
              </option>
            </select>

          </div> 
          <div><label for="province" :class="selected==1?'arrows':''" ></label></div>
          省
          <div>
            <select v-if="isShow" name="city" id="city" v-model="form.city" @click="selected==2"  style="width:4.65rem;">
              <option
                v-for="(item2, index2) in option2"
                :key="index2"
                :value="index2"
                 @click="selected==0"
              >
                {{ item2.text }}
              </option>
            </select>

            <select v-else name="city" id="city" v-model="city" @click="selected==2"  style="width:4.65rem;">
              <option>
                {{ city }}
              </option>
            </select>
            
          </div>
          <div><label for="city" :class="selected==2?'arrows':''"></label></div>
          市
          <div>
            <select v-if="isShow" name="district" id="district" v-model="form.district" @click="selected==3" style="width:5.7rem;">
              <option
                v-for="(item3, index3) in option3"
                :key="index3"
                :value="index3"
                 @click="selected==0"
              >
                {{ item3.text }}
              </option>
            </select>

            <select v-else name="district" id="district" v-model="district" @click="selected==3" style="width:5.7rem;">
              <option>
                {{district }}
              </option>
            </select>

            
          </div>
          <div><label for="district" :class="selected==3?'arrows':''"></label></div>
          区
        </div>

        <div class="form_list">
          <button class="submit" @click="submit()">提交</button>
        </div>
      </div>
    </div>
  </div>
</template>
<!--<script charset="utf-8" src="http://api.map.baidu.com/api?v=2.0&ak=OEZQSKY7OmN1rhnu2K9I2cuGhcP2CdlC"></script>
<script src="https://code.jquery.com/jquery-3.0.0.min.js"></script>

  <script type="text/javascript" src="https://webapi.amap.com/maps?v=1.3&key=f3b9d2d838094bdc32305542400f65aa"></script>-->
 
<script>
import * as api from "@/api/api";
import "@/views/ecore/index.css";
 import locations from "@/views/ecore/locations.js";

 //import AMap from 'AMap'
// import location from '../../views/ecore/positionLocation.js'


export default {
  name: "App",
  data() {
    return {
      form: {
        name: "",
        mobile: "",
        province: 0,
        city: 0,
        district: 0,
      },
      option1: locations,
      option2: [],
      option3: [],
      selected:0,

       // 高德地图获取当前位置
            location: '',
            lat: 0,
            lng: 0,
            province:"",
            city:"",
            district:"",
            isShow:true


    };
  },

  mounted() {
 
    this.getLocation(); // 调用获取地理位置



    document.title = "亦芯";
    (this.option2 = this.option1[this.form.province]["children"]),
      (this.option3 = this.option2[this.form.city]["children"]);
  },
  watch: {
    "form.province": {
      handler() {
        this.option2 = this.option1[this.form.province]["children"];
        this.option3 = this.option2[this.form.city]["children"];
      },
    },
    "form.city": {
      handler() {
        this.option3 = this.option2[this.form.city]["children"];
      },
    },
  },
  methods: {

  /** 获取高德地图定位 */
      getLocation() {
        //let _that = this
        let geolocation = location.initMap('map-container');
        AMap.event.addListener(geolocation, 'complete', result => {
          
             this.province=result.addressComponent.province;
             this.district=result.addressComponent.district
           
            if( this.city==''){
                 //window.location.reload();
                 this.city=result.addressComponent.province
            }else{
               this.city=result.addressComponent.city
            }
            // alert(this.province);
            // alert(this.city)
            // alert(this.district);return;
            // alert(result.addressComponent.adcode)
            console.log(this.province)
             console.log(this.city)
             console.log(this.district)
             this.isShow=false;
         
        })
      },

      
      


    async submit() {
      //判读
      if (this.form.name == "") {
        alert("姓名不能为空");return false;
      }
      if (!/^1[3456789]{1}\d{9}/.test(this.form.mobile)) {
        //alert.fail("手机号格式不正确");
        alert("手机号格式不正确");return false;
      }
      if(this.isShow){
        if (this.option1[this.form.province]["text"] === '') {
          //alert.fail("省份不能为空");
          alert("省份不能为空");return false;
        }
        if (this.option2[this.form.city]["text"]==='') {
          //alert.fail("城市不能为空");
          alert("城市不能为空");return false;
        }
        if (this.option3[this.form.district]["text"]==='') {
          //alert.fail("区域不能为空");
          alert("区域不能为空");return false;
        }

        //推送数据
        let data = await api.post("/push", {
          name: this.form.name,
          mobile: this.form.mobile,
          province: this.option1[this.form.province]["text"],
          city: this.option2[this.form.city]["text"],
          district: this.option3[this.form.district]["text"],
        });
        if (data.code == 0) {
          alert(data.message);
        } else {
          alert(data.message);
        }

      }else{
        if (this.province === '') {
          //alert.fail("省份不能为空");
          alert("省份不能为空");return false;
        }
        if (this.city === '') {
          //alert.fail("城市不能为空");
          alert("城市不能为空");return false;
        }
        if (this.district === '') {
          //alert.fail("区域不能为空");
          alert("区域不能为空");return false;
        }
        
        //推送数据
        let data = await api.post("/push", {
          name: this.form.name,
          mobile: this.form.mobile,
          province: this.province,
          city: this.city,
          district: this.district,
        });
        if (data.code == 0) {
          alert(data.message);
        } else {
          alert(data.message);
        }
      }

      
    },
    // 全部选项选择完毕后，会触发 finish 事件
  },
};
</script>

<style scoped >
</style>
