import axios from 'axios'

import { Notify , Toast } from 'vant'

//如果不需要开启代理就用下面的
// export const baseUrl =  '/api'
export const baseUrl = process.env.NODE_ENV === 'development' ? '/api' : process.env.VUE_APP_BASE_API
// export const baseUrl = process.env.NODE_ENV === 'development' ? '/api' : process.env.VUE_APP_API_URL || '/index.php'
// export const baseUrl = process.env.VUE_APP_BASE_API
// console.log(process.env)
const service = axios.create({
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'application/json',
    'withCredentials': true
  },
  timeout: 10000
})

let loading = null
service.interceptors.request.use(
  config => {
    const { showLoading } = config.myConfig
    if (showLoading) {
      // loading = Toast.loading({
      //   message: 'Loading...',
      //   forbidClick: false,
      // })
    }
    return config
  },
  error => {
    loading && loading.close()
    Notify({
      message: '请求错误！',
      type: 'danger',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  response => {
    loading && loading.close()
    const { showSuccessMsg, showFailMsg } = response.config.myConfig
    process.env.NODE_ENV === 'development'
    if (response.config.responseType === 'blob') {
      return response
    } else {
      const res = response.data
     /* if (res.result === true ) {
        if (showSuccessMsg) {
          Notify({
            message: res.message || '处理成功！',
            type: 'success',
            duration: 5 * 1000
          })
        }
      } else {
        // token失效跳转登录
        if (res.code === 10004) {
          Notify({
            message: '登录已过期,请重新登录！',
            type: 'danger',
            duration: 5 * 1000
          });
        }
        if (showFailMsg) {
          Notify({
            message: res.message || '处理失败！',
            type: 'danger',
            duration: 5 * 1000
          })
        }
      }*/
      return res
    }
  },
  error => {
    loading && loading.close()
    // 处理网络请求失败
    if (error.response) {
      switch (error.response.status) {
        case 404:
          Notify({
            message: '服务器未知故障！',
            type: 'danger',
            duration: 5 * 1000
          })
          break
        case 401:
          Notify({
            message: '401！',
            type: 'danger',
            duration: 5 * 1000
          })
          break
        case 500:
          Notify({
            message: '服务器错误！',
            type: 'danger',
            duration: 5 * 1000
          })
          break
        case 503:
          Notify({
            message: '服务器繁忙！',
            type: 'danger',
            duration: 5 * 1000
          })
          break
        default:
          Notify({
            message: '系统繁忙！',
            type: 'danger',
            duration: 5 * 1000
          })
      }
    } else {
      Notify({
        message: '系统繁忙！',
        type: 'danger',
        duration: 5 * 1000
      })
    }
    return Promise.reject(error)
  }
)

export default service
